/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import FAQ from '../components/FAQ'
import useAmplitude from '../hooks/useAmplitude'
import useOwnAffiliate from '../hooks/useOwnAffiliate'
import { INSTALL_CLICKED, PRICING_PLAN_CLICKED } from '../helpers/events'
import useCMS from '../hooks/useCMS'
import Testimonials from '../components/Testimonials'
import Features from '../components/Features'
import Pricing from '../components/Pricing'

export default function Home({ questions }) {
  const { logEvent } = useAmplitude()

  // init to track the referral if it's available
  useOwnAffiliate()

  const installClicked = (source) => {
    logEvent(INSTALL_CLICKED, { source })
  }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'VideoObject',
              name: 'How to add a transcript to your YouTube video.',
              description:
                'Let me show you how to add a transcript to your YouTube video with one click using our chrome extension',
              thumbnailUrl: [
                'https://i.ytimg.com/vi_webp/oOAA8I-EZbQ/maxresdefault.webp',
              ],
              uploadDate: '2022-01-10T12:00:00+08:00',
              duration: 'PT0M34S',
              embedUrl: 'https://www.youtube.com/embed/oOAA8I-EZbQ',
              interactionStatistic: {
                '@type': 'InteractionCounter',
                interactionType: { '@type': 'WatchAction' },
                userInteractionCount: 267,
              },
            }),
          }}></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        (function(w,d,v3){
          w.chaportConfig = {
            appId : '61e008c73f147b31014ec173',
            appearance: {
              windowColor: '#8b5cf6'
            }
          };
      
          if(w.chaport)return;v3=w.chaport={};v3._q=[];v3._l={};v3.q=function(){v3._q.push(arguments)};v3.on=function(e,fn){if(!v3._l[e])v3._l[e]=[];v3._l[e].push(fn)};var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://app.chaport.com/javascripts/insert.js';var ss=d.getElementsByTagName('script')[0];ss.parentNode.insertBefore(s,ss)})(window, document);
        `,
          }}></script>
      </Head>
      <section className="relative flex flex-col items-center justify-center w-full px-6 py-24 bg-white bg-cover lg:py-32 min-w-screen">
        <div className="flex flex-col items-center justify-center mx-auto sm:p-6 xl:p-8 lg:flex-row lg:max-w-6xl lg:p-0">
          <div className="container relative flex flex-col w-full px-5 pb-1 pr-12 mb-16 text-2xl text-gray-700 lg:w-1/2 sm:pr-0 md:pr-6 md:pl-0 lg:pl-5 xl:pr-10 sm:items-center lg:items-start lg:mb-0">
            <h1 className="relative text-4xl font-extrabold leading-none text-purple-500 xl:text-5xl sm:text-center lg:text-left">
              <span className="mt-1 text-gray-800 lg:mt-0">
                More <span className="text-purple-500">views.</span>
                <br />
                More <span className="text-purple-500">watch-time.</span>
                <br />
                <span className="text-purple-500">One</span> click.
              </span>
            </h1>
            <p className="relative block mt-6 text-base text-gray-500 sm:text-center lg:text-left">
              Transcribe directly from YouTube Studio, rank your videos better &
              make the subtitles pleasant to read.{' '}
              <span className="text-xs text-gray-400">
                (unlike YouTube&apos;s 😳)
              </span>
            </p>
            <div className="relative grid items-center w-full grid-cols-1 mt-4">
              <div className="flex flex-col">
                <a
                  href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff"
                  target="_blank"
                  onClick={() => installClicked('hero')}
                  className="relative flex flex-col items-center self-center justify-center w-full px-5 py-4 mt-5 text-base font-medium leading-tight text-center text-white transition duration-150 ease-in-out bg-purple-500 border border-transparent rounded-full shadow lg:py-4 hover:bg-purple-600 focus:outline-none focus:border-purple-600 md:text-lg xl:text-xl md:px-5 xl:px-10"
                  rel="noreferrer">
                  Grow my channel
                </a>
                <div className="box-border mt-2 text-xs text-center text-gray-400">
                  (30 minutes free, no credit card required)
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full px-5 rounded-lg lg:w-1/2 group xl:px-0">
            <p className="mb-4 ml-4 text-gray-400 lg:text-xl font-body">
              See how it works 👇{' '}
              <span className="text-sm">(In 30 seconds)</span>
            </p>
            <div className="relative m-auto overflow-hidden shadow-2xl cursor-pointer group rounded-3xl">
              <iframe
                className="object-fill w-full h-48 md:h-80"
                src="https://www.youtube.com/embed/oOAA8I-EZbQ"
                title="How to add a transcript to a new video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div className="box-border mt-32 text-xl font-bold text-center text-gray-800">
          &quot;Awesome and easy-to-use solution to add captions to videos
          quickly.&quot;
        </div>
        <div className="box-border object-cover mt-2 text-xs font-semibold text-center text-purple-400 underline uppercase">
          <a
            href="https://www.youtube.com/c/GeraldVersluis"
            target="_blank noreferrer">
            Gerald Versluis
            <span className="lowercase"> (&gt; 18k subs)</span>
          </a>
        </div>
      </section>

      <Testimonials />

      <Features />

      <section id="compare" className="w-full pt-24 pb-32 bg-white">
        <div className="relative px-4 mx-auto lg:px-12">
          <div className="relative grid items-center grid-cols-1 gap-10 p-12 overflow-hidden shadow-2xl bg-gradient-to-r from-purple-500 to-purple-600 md:grid-cols-5 lg:gap-0 rounded-3xl">
            <div className="absolute top-0 right-0">
              <svg
                viewBox="0 0 487 487"
                className="object-cover w-full h-full"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M487 486.7c-66.1-3.6-132.3-7.3-186.3-37s-95.9-85.3-126.2-137.2c-30.4-51.8-49.3-99.9-76.5-151.4C70.9 109.6 35.6 54.8.3 0H487v486.7z"
                  fill="#FFF"
                  fillRule="nonzero"
                  fillOpacity=".1"></path>
              </svg>
            </div>

            <div className="absolute bottom-0 left-0 h-full">
              <svg
                viewBox="0 0 487 487"
                className="w-auto h-full opacity-75 object-stretch"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 .3c67 2.1 134.1 4.3 186.3 37 52.2 32.7 89.6 95.8 112.8 150.6 23.2 54.8 32.3 101.4 61.2 149.9 28.9 48.4 77.7 98.8 126.4 149.2H0V.3z"
                  fill="#FFF"
                  fillRule="nonzero"
                  fillOpacity=".1"></path>
              </svg>
            </div>

            <div className="relative col-span-1 md:col-span-3">
              <h2 className="mb-1 text-3xl font-bold leading-tight text-white md:mb-3 md:text-3xl lg:text-4xl xl:text-5xl">
                Not convinced?{' '}
                <span className="block lg:mt-3">
                  See how our Transcript compares to YouTube&apos;s
                </span>
              </h2>
              <p className="max-w-sm my-6 text-base font-normal text-purple-300 xl:max-w-lg lg:pr-0 pr-7 lg:text-xl">
                Youtube&apos;s auto transcripts are <i>ok</i>... but they:{' '}
                <br /> &bull; don&apos;t count for SEO
                <br /> &bull; mash separate sentences together <br />
                &bull; don&apos;t have commas or periods
              </p>
              <p className="max-w-sm mt-6 text-base font-normal text-purple-300 xl:max-w-lg lg:pr-0 pr-7 lg:text-xl">
                Our Transcripts:
              </p>
              <li className="flex items-center py-2 space-x-4">
                <svg
                  className="flex-shrink-0"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                    fill="#EBFEEF"
                  />
                  <path
                    d="M13.9915 6.16828C13.9032 6.10103 13.8029 6.05241 13.6964 6.02521C13.59 5.99801 13.4793 5.99276 13.3708 6.00976C13.2624 6.02676 13.1582 6.06567 13.0643 6.12428C12.9704 6.18289 12.8886 6.26004 12.8236 6.35133L8.86325 11.9049L6.34491 9.82298C6.17127 9.68555 5.95243 9.62387 5.7353 9.65117C5.51817 9.67847 5.32004 9.79258 5.18337 9.96903C5.04669 10.1455 4.98236 10.3702 5.00417 10.5951C5.02597 10.82 5.13217 11.0271 5.3 11.172L8.49756 13.8148C8.58592 13.8862 8.68717 13.9387 8.79539 13.9692C8.90361 13.9997 9.01663 14.0077 9.12785 13.9926C9.23907 13.9774 9.34626 13.9396 9.44317 13.8812C9.54007 13.8228 9.62473 13.745 9.69222 13.6524L14.1713 7.37569C14.2363 7.28423 14.2832 7.18044 14.3093 7.07024C14.3355 6.96005 14.3403 6.84561 14.3236 6.73348C14.3069 6.62135 14.269 6.51371 14.212 6.41673C14.155 6.31975 14.0801 6.23533 13.9915 6.16828Z"
                    fill="#4AA753"
                  />
                </svg>
                <span className="text-sm font-medium text-purple-800 lg:text-xl">
                  <b>
                    <i>More Accurate</i> transcripts 🤪
                  </b>{' '}
                  - what&apos;s the point otherwise?
                </span>
              </li>
              <li className="flex items-center py-2 space-x-4">
                <svg
                  className="flex-shrink-0"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                    fill="#EBFEEF"
                  />
                  <path
                    d="M13.9915 6.16828C13.9032 6.10103 13.8029 6.05241 13.6964 6.02521C13.59 5.99801 13.4793 5.99276 13.3708 6.00976C13.2624 6.02676 13.1582 6.06567 13.0643 6.12428C12.9704 6.18289 12.8886 6.26004 12.8236 6.35133L8.86325 11.9049L6.34491 9.82298C6.17127 9.68555 5.95243 9.62387 5.7353 9.65117C5.51817 9.67847 5.32004 9.79258 5.18337 9.96903C5.04669 10.1455 4.98236 10.3702 5.00417 10.5951C5.02597 10.82 5.13217 11.0271 5.3 11.172L8.49756 13.8148C8.58592 13.8862 8.68717 13.9387 8.79539 13.9692C8.90361 13.9997 9.01663 14.0077 9.12785 13.9926C9.23907 13.9774 9.34626 13.9396 9.44317 13.8812C9.54007 13.8228 9.62473 13.745 9.69222 13.6524L14.1713 7.37569C14.2363 7.28423 14.2832 7.18044 14.3093 7.07024C14.3355 6.96005 14.3403 6.84561 14.3236 6.73348C14.3069 6.62135 14.269 6.51371 14.212 6.41673C14.155 6.31975 14.0801 6.23533 13.9915 6.16828Z"
                    fill="#4AA753"
                  />
                </svg>
                <span className="text-sm font-medium text-purple-800 lg:text-xl">
                  <b>Punctuation and Capitalization ❓</b> - humans will read it
                </span>
              </li>
              <li className="flex items-center py-2 space-x-4">
                <svg
                  className="flex-shrink-0"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                    fill="#EBFEEF"
                  />
                  <path
                    d="M13.9915 6.16828C13.9032 6.10103 13.8029 6.05241 13.6964 6.02521C13.59 5.99801 13.4793 5.99276 13.3708 6.00976C13.2624 6.02676 13.1582 6.06567 13.0643 6.12428C12.9704 6.18289 12.8886 6.26004 12.8236 6.35133L8.86325 11.9049L6.34491 9.82298C6.17127 9.68555 5.95243 9.62387 5.7353 9.65117C5.51817 9.67847 5.32004 9.79258 5.18337 9.96903C5.04669 10.1455 4.98236 10.3702 5.00417 10.5951C5.02597 10.82 5.13217 11.0271 5.3 11.172L8.49756 13.8148C8.58592 13.8862 8.68717 13.9387 8.79539 13.9692C8.90361 13.9997 9.01663 14.0077 9.12785 13.9926C9.23907 13.9774 9.34626 13.9396 9.44317 13.8812C9.54007 13.8228 9.62473 13.745 9.69222 13.6524L14.1713 7.37569C14.2363 7.28423 14.2832 7.18044 14.3093 7.07024C14.3355 6.96005 14.3403 6.84561 14.3236 6.73348C14.3069 6.62135 14.269 6.51371 14.212 6.41673C14.155 6.31975 14.0801 6.23533 13.9915 6.16828Z"
                    fill="#4AA753"
                  />
                </svg>
                <span className="text-sm font-medium text-purple-800 lg:text-xl">
                  <b>Easy to get 99.9% accuracy ✨</b> - it ranks you better
                </span>
              </li>
              <a
                href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff"
                className="flex items-center justify-center w-full px-4 py-2 mt-6 text-base font-medium leading-6 text-purple-600 whitespace-no-wrap bg-white border-2 border-transparent rounded-full shadow-sm md:w-auto md:inline-flex hover:bg-transparent hover:text-white hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-700">
                Get Yours
              </a>
              <a
                href="#proof"
                className="flex items-center justify-center w-full px-4 py-2 mt-6 ml-2 text-base font-medium leading-6 text-center text-white whitespace-no-wrap border-2 border-white rounded-full shadow-sm md:w-auto md:inline-flex hover:bg-transparent hover:text-white hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-700">
                Unbiased opinion plz 😏
              </a>
            </div>

            <div className="flex flex-col items-center h-full col-span-1 lg:flex-row md:col-span-2">
              <div className="flex-col px-2 py-4 mb-4 space-y-4 bg-purple-800 shadow-xl w-60 rounded-xl lg:mb-0 lg:mr-4">
                <p className="text-xl font-bold text-center text-purple-300">
                  Youtube
                </p>
                <p className="text-purple-400">
                  <span className="font-bold">[00:00]</span> no freaking way
                  that brett maverick&apos;s
                </p>
                <p className="text-purple-400">
                  <span className="font-bold">[00:02]</span> jawline is
                </p>
                <p className="text-purple-400">
                  <span className="font-bold">[00:03]</span> natural welcome
                  back to the first
                </p>
                <p className="text-purple-400">
                  <span className="font-bold">[00:05]</span> episode of jawline
                  review which is
                </p>
                <p className="text-purple-400">
                  <span className="font-bold">[00:07]</span> quite simply
                  exactly what it sounds like
                </p>
                <p className="text-purple-400">
                  <span className="font-bold">[00:09]</span> we review
                  people&apos;s jawlines we&apos;re going
                </p>
              </div>
              <div className="flex-col px-2 py-4 space-y-4 bg-purple-800 shadow-xl w-60 rounded-xl">
                <p className="text-xl font-bold text-center text-purple-100">
                  Ours
                </p>
                <p className="text-purple-200">
                  <span className="font-bold">[00:00]</span> No fricking way
                  that Brett Maverick&apos;s jawline is natural.
                </p>
                <p className="text-purple-200">
                  <span className="font-bold">[00:04]</span> Welcome back to the
                  first episode of jawline review,
                </p>
                <p className="text-purple-200">
                  <span className="font-bold">[00:06]</span> which is quite
                  simply exactly what it sounds like.
                </p>
                <p className="text-purple-200">
                  <span className="font-bold">[00:09]</span> We review
                  people&apos;s jawlines so we&apos;re going to be looking at an
                  absolute Maverick
                </p>
                <p className="text-purple-200">
                  <span className="font-bold">[00:14]</span> called Brett
                  Maverick.
                </p>
              </div>
            </div>
            <a
              href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff"
              className="z-30 flex items-center justify-center w-full px-4 py-2 mt-8 text-base font-medium leading-6 text-purple-600 whitespace-no-wrap bg-white border-2 border-transparent rounded-full shadow-sm md:hidden md:w-auto hover:bg-transparent hover:text-white hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-700">
              Get Yours
            </a>
          </div>
        </div>
      </section>

      <section id="proof" className="py-20 bg-white">
        <div className="max-w-3xl px-4 mx-auto lg:px-12 xl:px-5">
          <div className="flex flex-col justify-center space-y-8">
            <div className="flex flex-col w-full px-8 py-12 mx-auto shadow-xl bg-gray-50 rounded-3xl">
              <h2 className="w-full mx-auto mb-8 text-4xl font-extrabold leading-none text-center text-gray-900 sm:text-5xl md:text-7xl">
                Why does it work?
              </h2>
              <p className="mb-4 ml-4 text-xl text-gray-400 font-body">
                Let the experts tell you 👇{' '}
                <span className="text-sm text-gray-400">(in 30 seconds)</span>
              </p>
              <iframe
                className="object-fill w-full h-64 shadow-2xl cursor-pointer md:h-96 group rounded-3xl"
                src="https://www.youtube.com/embed/NY3y0V9UDwM?start=794"
                title="How to add a transcript to a new video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="px-8 py-6 my-8 text-sm bg-white rounded-xl lg:text-lg">
                <p className="mb-2 text-xl text-gray-800">
                  In addition to helping you rank on YouTube, transcripts...
                </p>
                <ul className="mt-8 space-y-8 text-gray-500 list-decimal list-inside">
                  <li>
                    Help reach more of the{' '}
                    <a
                      className="underline"
                      target="_blank noreferrer"
                      href="https://www.nexttv.com/news/mobile-videos-often-watched-without-audio-study-finds">
                      80%
                    </a>{' '}
                    of US viewers that watch video without sound 😳{' '}
                    <span className="text-xs text-gray-300">(More reach)</span>
                  </li>
                  <li>
                    Help you get{' '}
                    <a
                      className="underline"
                      href="https://www.facebook.com/business/news/updated-features-for-video-ads"
                      target="_blank noreferrer">
                      12%
                    </a>{' '}
                    more watch-time on average 📈{' '}
                    <span className="text-xs text-gray-300">
                      (More retention)
                    </span>
                  </li>
                  <li>
                    Help the{' '}
                    <a
                      className="underline"
                      href="https://www.nidcd.nih.gov/health/statistics/quick-statistics-hearing"
                      target="_blank noreferrer">
                      15%
                    </a>{' '}
                    of the US and{' '}
                    <a
                      className="underline"
                      href="https://www.who.int/news-room/fact-sheets/detail/deafness-and-hearing-loss"
                      target="_blank noreferrer">
                      5%
                    </a>{' '}
                    of the world population with hearing problems understand
                    your video ❤️{' '}
                    <span className="text-xs text-gray-300">
                      (More accessibility)
                    </span>
                  </li>
                </ul>
              </div>
              <a
                href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff"
                target="_blank"
                onClick={() => installClicked('why')}
                className="flex items-center justify-center px-5 py-2 mt-5 text-base font-medium leading-tight text-white transition duration-150 ease-in-out bg-purple-500 border border-transparent rounded-full shadow lg:py-4 hover:bg-purple-600 focus:outline-none focus:border-purple-600 focus:shadow-purple-NaN md:text-lg xl:text-xl md:px-5 xl:px-10"
                rel="noreferrer">
                I want to grow my channel 🚀
              </a>
            </div>
          </div>
        </div>
      </section>

      <Pricing
        onSelectSubscriptionPlan={(data) => {
          logEvent(PRICING_PLAN_CLICKED, data)
          window.open(
            'https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff',
            '_blank',
          )
        }}
      />
      <FAQ questions={questions} />
      <section
        id="bottom-cta"
        className="flex items-center justify-center py-32 bg-white">
        <a
          href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff"
          target="_blank"
          onClick={() => installClicked('bottom-cta')}
          className="flex items-center justify-center max-w-md px-5 py-2 mt-5 text-base font-medium leading-tight text-white transition duration-150 ease-in-out bg-purple-500 border border-transparent rounded-full shadow lg:py-4 hover:bg-purple-600 focus:outline-none focus:border-purple-600 focus:shadow-purple-NaN md:text-lg xl:text-xl md:px-5 xl:px-10"
          rel="noreferrer">
          Enough reading. I want to start!
        </a>
      </section>
    </>
  )
}

export async function getStaticProps() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getFAQs } = useCMS()
  const questions = await getFAQs()

  return {
    props: {
      questions,
    },
    revalidate: 600,
  }
}

Home.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>
}

Home.propTypes = {
  article: PropTypes.object,
  questions: PropTypes.array,
}
