import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../../assets/spinner.svg'
import CheckmarkIcon from '../../assets/checkmark.svg'

export default function CTAButton({
  className,
  text,
  disabled,
  loading,
  success,
  onClick,
  children,
}) {
  const getContent = () => {
    if (loading) {
      return <Spinner width={24} height={24} className="text-white" />
    }

    if (success) {
      return <CheckmarkIcon width={24} height={24} className="text-green-500" />
    }

    return text
  }

  return (
    <button
      className={`flex items-center justify-center disabled:bg-gray-400 disabled:text-gray-500 ${
        success ? 'bg-green-500' : 'bg-purple-500'
      } hover:bg-purple-800 text-white font-body focus:ring-4 focus:ring-purple-500 focus:ring-opacity-50 focus:ring-offset-2 focus:outline-none font-bold py-2 px-4 rounded-full focus:outline-none ${className}`}
      type="button"
      disabled={(disabled || loading) && !success}
      onClick={onClick}>
      {children || getContent()}
    </button>
  )
}

CTAButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

CTAButton.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  loading: false,
  success: false,
}
