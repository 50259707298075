import React from 'react'
import { FAQPageJsonLd } from 'next-seo'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function FAQ({ questions = [] }) {
  const faqs = questions.map((q) => {
    return {
      questionName: q.question,
      acceptedAnswerText: q.answer,
    }
  })

  return (
    <>
      <FAQPageJsonLd mainEntity={faqs} />
      <section
        id="faq"
        className="py-12 bg-purple-600 sm:py-16 md:py-20 lg:py-24 pb-28">
        <div className="px-4 py-12 mx-auto max-w-7xl sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-purple-500">
            <h2 className="text-3xl font-extrabold text-center text-purple-100 sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-purple-500">
              {questions.map(({ id, question, answer }) => (
                <Disclosure as="div" key={id} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex items-start justify-between w-full text-lg text-left text-purple-400 lg:text-xl">
                          <span className="font-medium text-purple-50">
                            {question}
                          </span>
                          <span className="flex items-center ml-6 h-7">
                            <ChevronDownIcon
                              className={cx(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform',
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="pr-12 mt-2">
                        <div
                          className="px-1 pt-0 mt-1 text-purple-200 sm:text-lg py-7"
                          dangerouslySetInnerHTML={{
                            __html: answer,
                          }}></div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </section>
    </>
  )
}

FAQ.propTypes = {
  questions: PropTypes.array,
}
