/* eslint-disable @next/next/no-img-element */
import React from 'react'

export default function Testimonials() {
  return (
    <section
      id="testimonials"
      className="box-border relative block w-full overflow-hidden leading-6 text-left text-white bg-purple-600">
      <h2 className="px-2 mx-auto mt-24 mb-8 text-3xl font-extrabold leading-none text-center text-white w- sm:text-4xl md:text-6xl">
        Used by amazing YouTubers like you
      </h2>
      <div className="max-w-6xl px-12 py-16 mx-auto leading-6 text-left md:py-32">
        <div className="box-border flex flex-wrap justify-center text-center text-white lg:justify-start lg:text-left">
          <div className="relative flex w-full pb-20 border-b border-purple-700 md:w-1/2 md:pb-0 md:pr-8 md:border-b-0 md:border-r">
            <a
              href="https://www.youtube.com/c/ZulieRaneWrites"
              target="_blank noreferrer">
              <img
                src="https://yt3.ggpht.com/ytc/AKedOLQUi3ayg2YxbkHloL3-KQ0NWog0L5C2CpG5HwtR8A=s176-c-k-c0x00ffffff-no-rj"
                className="box-border object-cover w-16 text-white align-middle border-none rounded-full"
              />
            </a>
            <div className="relative w-full px-4 leading-6 text-left">
              <div className="box-border text-sm text-white">
                &quot;Tube Transcripts is an awesome and easy-to-use solution to
                add captions to videos quickly.
                <br />
                <br />
                As a YouTube creator, I know transcripts and captions are vital
                for SEO.
                <br />
                <br />
                Plus, I wanted to make my videos more accessible for my
                audience.
                <br />
                <br />
                But the native YouTube auto-transcribe tool doesn&apos;t happen
                right away and it&apos;s not the most accurate, and I never have
                time to do it myself.
                <br />
                <br />
                Tube Transcripts does it for me in literally a single button
                click. Can&apos;t recommend it highly enough!&quot;
              </div>
              <div className="box-border object-cover mt-4 text-sm font-semibold text-purple-300 underline uppercase">
                <a
                  href="https://www.youtube.com/c/ZulieRaneWrites"
                  target="_blank noreferrer">
                  Zulie Rane<span className="lowercase"> (&gt; 20k subs)</span>
                </a>
              </div>
            </div>
          </div>
          <div className="relative flex w-full pt-20 pb-20 border-b border-purple-700 md:w-1/2 md:pt-0 md:pl-8 md:border-b-0">
            <a
              href="https://www.youtube.com/channel/UC-zet8Eyyy-OUE_fmocp3Wg"
              target="_blank noreferrer">
              <img
                src="https://yt3.ggpht.com/ytc/AKedOLTdyzCN3AOsMTvcKr1d_NuHzwXLGbWcIOJenTHA=s176-c-k-c0x00ffffff-no-rj"
                className="box-border object-cover w-16 text-white align-middle border-none rounded-full"
              />
            </a>
            <div className="relative w-full px-4 leading-6 text-left">
              <div className="box-border text-sm text-white">
                &quot;Tube Transcripts allow me to add captions in a single
                click.
                <br />
                <br />
                As a developer and advocate of Accessibility, this is
                incrediblely important to me.
                <br />
                <br />
                The native YouTube tool struggles with technical terms, my
                accent and is inaccurate.
                <br />
                <br />
                Tube Transcripts does it for me in literally a single button
                click and is incredibly accurate.
                <br />
                <br />I can&apos;t recommend it highly enough!&quot;
              </div>
              <div className="box-border object-cover mt-4 text-sm font-semibold text-purple-300 underline uppercase">
                <a
                  href="https://www.youtube.com/channel/UC-zet8Eyyy-OUE_fmocp3Wg"
                  target="_blank noreferrer">
                  James Perkins
                  <span className="lowercase"> (&gt; 7k subs)</span>
                </a>
              </div>
            </div>
          </div>
          <div className="relative flex w-full pb-20 mt-24 border-b border-purple-700 md:w-1/2 md:pb-0 md:pr-8 md:border-b-0 md:border-r">
            <a
              href="https://www.youtube.com/c/GeraldVersluis"
              target="_blank noreferrer">
              <img
                src="https://yt3.ggpht.com/4GdL0ktZSuhi5Cbl_2AnuO24nVbDNvqQ4XYtS85DhJaUoB_GrnwrSJMao1mMGJICODrVTa_kens=s176-c-k-c0x00ffffff-no-rj"
                className="box-border object-cover w-16 text-white align-middle border-none rounded-full"
              />
            </a>
            <div className="relative w-full px-4 leading-6 text-left">
              <div className="box-border text-sm text-white">
                &quot;Captions are easily overlooked, but super important!
                <br />
                <br />
                Both to make your videos more accessible and inclusive, but also
                it can work wonders for your SEO.
                <br />
                <br />
                With Tube Transcripts creating captions have never been easier!
                <br />
                <br />
                You can get AI powered captions with a click of the button and
                you never even have to leave the YouTube Creator Studio.
                <br />
                <br />
                In a short period the technical terms I have been using were
                picked up much better with the generated captions and I just
                have to do a few touch ups here and there.
                <br />
                <br />
                Huge timesaver.
                <br />
                <br />
                On top of this all, the team behind Tube Transcripts have been
                super nice and helpful and open to any feedback.
                <br />
                <br />
                10/10 would recommend again!
                <br />
                <br />
                Tube Transcripts is an awesome and easy-to-use solution to add
                captions to videos quickly.&quot;
              </div>
              <div className="box-border object-cover mt-4 text-sm font-semibold text-purple-300 underline uppercase">
                <a
                  href="https://www.youtube.com/c/GeraldVersluis"
                  target="_blank noreferrer">
                  Gerald Versluis
                  <span className="lowercase"> (&gt; 20k subs)</span>
                </a>
              </div>
            </div>
          </div>
          <div className="relative flex w-full pt-20 md:w-1/2 md:pt-0 md:pl-8 md:mt-24">
            <a
              href="https://www.youtube.com/c/MattPhelpss"
              target="_blank noreferrer">
              <img
                src="https://yt3.ggpht.com/p3vGCy8AIi4Y-mJJMJTTFfNQjrxcb5iDgBysc3tJc5CBr3s1pdGg1Wt6MtZIr3MYI9ZqGs0Eu_Y=s176-c-k-c0x00ffffff-no-r"
                className="box-border object-cover w-16 text-white align-middle border-none rounded-full"
              />
            </a>
            <div className="relative w-full px-4 leading-6 text-left">
              <div className="box-border text-sm text-white">
                &quot;Look I&apos;m biased but the only reason this YouTube
                Transcripts exists is because I wasn&apos;t happy with the
                existing solutions to transcribe videos on YouTube.
                <br />
                <br />
                They were either too clunky, too inaccurate or too expensive.
                <br />
                <br />
                This gives me a nice YouTube SEO boost with one click and I can
                keep focusing on making videos.
                <br />
                <br />
                Plus, nothing better than to scratch your own itch when making a
                product 😜&quot;
              </div>
              <div className="box-border object-cover mt-4 text-sm font-semibold text-purple-300 underline uppercase">
                <a
                  href="https://www.youtube.com/c/MattPhelpss"
                  target="_blank noreferrer">
                  Matt Phelps
                  <span className="lowercase"> (&gt; 46k subs)</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
