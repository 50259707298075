/* eslint-disable @next/next/no-img-element */
import React from 'react'
import Link from 'next/link'
import { CheckmarkIcon } from 'react-hot-toast'
import { INSTALL_CLICKED } from '../../helpers/events'
import useAmplitude from '../../hooks/useAmplitude'

export default function Features() {
  const { logEvent } = useAmplitude()

  return (
    <section
      id="features"
      className="box-border relative block w-full overflow-hidden text-white bg-purple-600">
      <div className="py-8 overflow-hidden bg-gray-50 lg:py-16">
        <h2 className="px-2 mx-auto mt-24 mb-8 text-4xl font-extrabold leading-none text-center text-gray-800 sm:text-5xl md:text-6xl">
          What makes our transcripts unique?
        </h2>
        <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true">
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="max-w-3xl mx-auto text-xl font-extrabold leading-8 tracking-tight text-center text-gray-600 lg:text-2xl">
              Human transcripts quality for 4% of the costs, done in minutes
              instead of hours 😱
            </h2>
            <p className="text-xs text-center text-gray-400">
              ($0.05 per minute instead of $1.25 😳)
            </p>
            <p className="max-w-3xl mx-auto mt-8 text-center text-gray-500">
              We use AI to transcribe your videos with ~90% accuracy out of the
              box.
              <br />
              Our tools help you squeeze out the to remaining 10% with 0 hassle.
              <br />
              See how 👇
            </p>
          </div>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Add your niche keywords
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Keywords help make sure we try to guess correctly if the 🤖 is
                unsure and has to decide between 2 options.
              </p>
            </div>

            <div className="relative mt-10 -mx-4 lg:mt-0" aria-hidden="true">
              <svg
                className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404">
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse">
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto"
                width={490}
                src="https://www.tubetranscripts.com/keywords-feature.png"
                alt="Custom YouTube transcript keywords for better YouTube search rankings feature screenshot"
              />
            </div>
          </div>

          <svg
            className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true">
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>

          <div className="relative mt-24 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Add custom mappings
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  Try our custom mappings if your transcripts include unusual
                  terms like SaaS, MRR or your name is very unique like our good
                  Friend{' '}
                  <a
                    href="https://www.youtube.com/c/ZulieRaneWrites"
                    className="underline"
                    target="_blank noreferrer">
                    Zulie
                  </a>
                  &apos;s.
                </p>
              </div>

              <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
                <img
                  className="relative mx-auto"
                  width={490}
                  src="https://www.tubetranscripts.com/mappings-feature.png"
                  alt="Custom YouTube transcript mappings to get a more accurate transcript"
                />
              </div>
            </div>
            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Fix the last 3% with 0 hassle
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  We show you words that our 🤖 has low confidence in directly
                  in the Subtitles Editor in YouTube studio. Scan your
                  transcript in seconds and reach Human Transcription quality
                  with ease
                </p>
              </div>

              <div className="relative mt-10 -mx-4 lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                  width={784}
                  height={404}
                  fill="none"
                  viewBox="0 0 784 404">
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse">
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={784}
                    height={404}
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <img
                  className="relative mx-auto rounded-2xl"
                  width={490}
                  src="https://www.tubetranscripts.com/editor-feature.png"
                  alt="Editor for your transcript directly built into YouTube studio"
                />
              </div>
            </div>
            <Link href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff">
              <a
                onClick={() =>
                  logEvent(INSTALL_CLICKED, { source: 'features' })
                }
                target="_blank"
                className="z-20 hidden w-1/2 px-4 py-2 mx-auto mt-24 text-base font-medium leading-6 text-center text-white bg-purple-500 border border-transparent rounded-full shadow-sm lg:block whitespace-nowrap hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                Grow my channel
              </a>
            </Link>
            <div>
              <h2 className="max-w-2xl mx-auto mt-24 text-xl font-extrabold leading-8 tracking-tight text-center text-gray-600 lg:text-2xl">
                Btw... YouTube subtitles are not a silver bullet, but they are a
                good fit for you if 👇
              </h2>
              <ul className="mx-auto mt-8 text-center text-gray-600 w-fit">
                <li className="flex space-x-2">
                  <CheckmarkIcon className="w-5 h-5" />
                  <p>You&apos;re serious about YouTube SEO</p>
                </li>
                <li className="flex space-x-2">
                  <CheckmarkIcon className="w-5 h-5" />
                  <p>You care about accessibility</p>
                </li>
                <li className="flex space-x-2">
                  <CheckmarkIcon className="w-5 h-5" />
                  <p>You target a specific niche</p>
                </li>
                <li className="flex space-x-2">
                  <CheckmarkIcon className="w-5 h-5" />
                  <p>You know that SEO is a long-term game</p>
                </li>
              </ul>
              <Link href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff">
                <a
                  onClick={() =>
                    logEvent(INSTALL_CLICKED, { source: 'who-its-for' })
                  }
                  target="_blank"
                  className="z-20 hidden px-4 py-2 mx-auto mt-6 text-base font-medium leading-6 text-center text-purple-500 underline w-fit lg:block whitespace-nowrap focus:outline-none">
                  Yup. Sounds like me!
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
