/* eslint-disable @next/next/no-img-element */
import React from 'react'
import CTAButton from '../CTAButton'

export default function Pricing({ onSelectSubscriptionPlan }) {
  const products = [
    {
      id: 'prod_L7pi4uHMJ6ecpy',
      name: '👶 I upload to YouTube weekly',
      priceId: 'price_1KRa3JJ6L3NQCzgEp3Ej7j6V',
      subtitle: '90 minutes of transcripts / month',
      price: 999,
      metadata: {
        tier: 'beginner',
      },
    },
    {
      id: 'prod_L7piufXtClHwXr',
      name: '🚀 I upload to YouTube every 2nd day',
      priceId: 'price_1KRa3DJ6L3NQCzgE4AMzM9TC',
      subtitle: '360 minutes of transcripts / month',
      price: 1999,
      metadata: {
        tier: 'growth',
      },
    },
    {
      id: 'prod_L7pjpVM26P0DVC',
      name: "📈 I can't stop uploading to YouTube",
      priceId: 'price_1KRa3DJ6L3NQCzgE4AMzM9TC',
      subtitle: 'Unlimited minutes / month',
      price: 4999,
      metadata: {
        tier: 'unlimited',
      },
    },
  ]

  return (
    <section
      id="pricing"
      className="box-border relative block w-full px-4 py-12 overflow-hidden leading-6 text-left text-gray-800 bg-white">
      <h2 className="px-2 mx-auto mt-24 mb-8 text-4xl font-extrabold leading-none text-center sm:text-5xl md:text-7xl">
        Our Transcription Plans
      </h2>
      <div className="flex items-center justify-center mt-12 mb-16">
        <ul className="mx-auto text-gray-500 list-disc lg:text-xl">
          <li>😳 5% the price of a human transcript</li>
          <li>⚡️ 3 minute turnaround (for a 10 minute video)</li>
          <li>🎉 30 minute free trial</li>
          <li>💰 No credit card required during trial</li>
          <li>✅ Cancel subscription at any time</li>
        </ul>
      </div>
      <div className="flex flex-wrap justify-center mb-5 gap-x-4 gap-y-12">
        {products?.map((product, index) => (
          <div
            key={product.id}
            className={`flex flex-col relative max-w-sm py-8 px-8 bg-white rounded-xl ${
              index == 1
                ? 'ring-2 ring-purple-500 shadow-lg shadow-purple-400'
                : 'ring-1 ring-gray-300'
            } `}>
            <span
              className={`absolute -top-3 left-[31%] rounded-full bg-purple-500 antialiased text-xs text-white text-center py-1 px-10 ${
                index == 1 ? 'opacity-100' : 'opacity-0'
              }`}>
              Best Value
            </span>
            <div className="mt-2">
              <p className="mb-2 text-2xl font-bold text-center text-gray-700">
                {product.name}
                <br />
                <span className="mt-4 text-2xl text-gray-400">
                  (${product.price / 100})
                </span>
              </p>
              <p className="mt-4 font-bold text-center text-gray-400">
                {product.subtitle}
              </p>
            </div>
            <CTAButton
              className="py-3 mx-auto mt-12 text-lg font-bold"
              onClick={() =>
                onSelectSubscriptionPlan({
                  priceId: product.priceId,
                  tier: product.metadata.tier,
                })
              }>
              <p className="flex items-center">
                Start Free Trial
                <span className="pl-1 text-xs text-purple-200">
                  (30 minutes)
                </span>
              </p>
            </CTAButton>
            <p className="mt-2 text-xs text-center text-gray-400">
              No Credit Card required.
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}
