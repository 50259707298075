import { useEffect } from 'react'
import { useRouter } from 'next/router'

export default function useAffiliate() {
  const router = useRouter()

  useEffect(() => {
    if (router.query.via) {
      window.localStorage.setItem('affiliate', router.query.via)
    }
  }, [router.query])

  const getAffiliateId = () => {
    return (
      window.localStorage.getItem('affiliate') ||
      'checkout_' + new Date().getTime()
    )
  }

  return {
    getAffiliateId,
  }
}
